@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rottateIcon {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.react-datepicker-wrapper {
  vertical-align: middle;
}

.react-datepicker__input-container input,
.form-control {
  background-color: transparent;
  border: 2px solid #d4d6d8;
  border-radius: 5px;
  padding: 14px 15px;
  font-size: 16px;
  width: 100%;
}

.form-control:focus {
  border-color: #d4d6d8;
  outline: none;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.react-stockchart {
  overflow-x: auto;
  width: 100% !important;
}

svg.react-stockchart {
  min-width: 610px;
}

.main_content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  display: block;
}

.StripeElement {
  border: 2px solid #d4d6d8;
  padding: 10px 15px;
  border-radius: 5px;
}

.MuiTooltip-popper {
  margin-top: -10px !important;
  margin-right: -10px !important;
  margin-bottom: -10px;
  z-index: 8 !important;
}

#menu-price .MuiPopover-paper,
#menu-marketCap .MuiPopover-paper,
#menu-selectedSector .MuiPopover-paper {
  max-width: -moz-calc(100% - 90px);
  max-width: -webkit-calc(100% - 90px);
  max-width: calc(100% - 90px);
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 1);
  padding: 2px 5px;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.tooltip-box .custom-tooltip.up::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: -20%;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: rgba(255, 255, 255, 1);
}

.tooltip-box .custom-tooltip.down::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: rgba(255, 255, 255, 1);
}

.tooltip-box .custom-tooltip.left::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 25%;
  left: 1%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: rgba(255, 255, 255, 1);
}

.custom-tooltip.left .label {
  margin-left: 5px;
}

.tooltip-box .custom-tooltip.right::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 25%;
  left: 100%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: rgba(255, 255, 255, 1);
}

.MuiDialog-root {
  z-index: 1502 !important;
}

@media only screen and (max-width: 1280px) {
  .adsbygoogle {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media (max-width: 460px) {
  .piechart-box {
    width: 310px;
    height: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .canvas_wrap canvas {
    height: 400px !important;
    margin-top: 15px;
  }

  .canvas_wrap_pie canvas {
    height: 100% !important;
    max-width: 500px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .canvas_wrap canvas {
    height: 300px !important;
    width: 100%;
  }
}

@media (max-width: 700px) and (min-width: 600px) {
  .highcharts-root {
    height: 350px !important;
  }
}

@media (max-width: 600px) and (min-width: 530px) {
  .highcharts-root {
    height: 300px !important;
  }
}

.line-height .highcharts-root {
  height: unset !important;
}

.pie-height {
  height: 235px !important;
}

@media only screen and (max-width: 599px) {
  .react-datepicker__input-container input {
    padding: 11px 15px;
    font-size: 12px;
  }

  .MuiTooltip-popper {
    margin-top: -20px !important;
    margin-right: -20px !important;
    margin-bottom: -20px;
  }

  .highcharts-root {
    height: 230px;
  }
}

@media only screen and (max-width: 350px) {
  .canvas_wrap_pie canvas {
    width: 250px !important;
  }
}

.list-wrap {
  margin: 0;
  padding: 0;
}

.list-wrap li {
  font-size: 16px;
  list-style: none;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  border: 1px solid #0b6a3c;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 11px;
  width: 100%;
  opacity: 1;
  height: auto;
  background-color: transparent;
  box-shadow: 0px 10px 25px #20ee8b1a;
}

.list-wrap li svg {
  fill: #fff;
  vertical-align: middle;
  width: 16px;
  font-size: 16px;
  margin-right: 5px;
}

.list-wrap .swiper-pagination-bullet-active {
  background: #0ab799;
  background: -moz-linear-gradient(top, #0ab799 0%, #1fee8b 100%);
  background: -webkit-linear-gradient(top, #0ab799 0%, #1fee8b 100%);
  background: linear-gradient(to bottom, #0ab799 0%, #1fee8b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0ab799', endColorstr='#1fee8b', GradientType=0);
}

.topBannerShow,
.notification-banner {
  padding-top: 55px !important;
  position: relative;
}

.removePadding {
  padding-top: 0;
}

.topBannerShow .bannerShow,
.notification-banner .bannerShow {
  transform: translateY(0);
}

.topBannerShow .top-header,
.notification-banner .top-header {
  margin-top: 55px;
}

.topBannerShow .MuiDrawer-paperAnchorTop,
.notification-banner .MuiDrawer-paperAnchorTop {
  top: 50px;
  padding-bottom: 50px;
}

body {
  transition: padding-top 0.8s cubic-bezier(0.3, 0, 0.175, 1);
}

.top-header.MuiPaper-root {
  transition: margin-top 0.8s cubic-bezier(0.3, 0, 0.175, 1);
}

.sidebar-drawer > .MuiDrawer-paper,
.topBannerShow .main_content > header .MuiBox-root,
.notification-banner .main_content > header .MuiBox-root {
  transition: all 0.8s cubic-bezier(0.3, 0, 0.175, 1) !important;
}

@media only screen and (min-width: 960px) {
  .topBannerShow .sidebar-drawer > .MuiDrawer-paper,
  .notification-banner .sidebar-drawer > .MuiDrawer-paper {
    transform: translateY(55px);
    padding-bottom: 55px;
  }
}

.react-joyride__overlay {
  z-index: 2000 !important;
}
.__floater {
  z-index: 2001 !important;
  max-width: min(calc(100% - 20px), 420px) !important;
  width: 100%;
}
.__floater__body button span {
  text-transform: none;
}
